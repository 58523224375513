






























import { blockchainHandler } from '@/blockchainHandlers'
import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Observer
@Component
export default class ConnectMetamask extends Vue {
  @Prop() requiredChainId!: any
  @Prop({ default: false }) block!: boolean
  @Prop({ default: false }) large!: boolean
  @Prop({ default: false }) small!: boolean

  walletStore = walletStore
  networkName = ''

  @Watch('requiredChainId', { immediate: true }) changeId(value) {
    if (value) {
      const { name } = blockchainHandler.getChainConfig(this.requiredChainId)
      this.networkName = name
    }
  }
}
